import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store';
import { openDealModal, selectDeal } from '../../../store/Deals/reducer';
import { selectSelectedDeal } from '../../../store/Deals/selector';
import { DealResults } from '../../types/Deals';
import './index.scss';

const DealModal = () => {
  const dispatch = useAppDispatch();
  const deal = useSelector(state => selectSelectedDeal(state)) as DealResults;
  const onClose = () => {
    dispatch(selectDeal(null))
    dispatch(openDealModal(false))
  }

  const getKeyType = (key: string, object: any) => {
    return typeof (object as any)[key];
  }

  const handleValues: any = (key: string, object: any) => {
    const type = getKeyType(key, object);
    switch (type) {
      case 'undefined':
      case 'symbol':
        return (
          <div className="deal_field">
            <label>{key.toUpperCase()}</label>
            <p>N/A</p>
          </div>
        )
      case 'object':
        if (!object[key]) {
          return (
            <div className="deal_field">
              <label>{key.toUpperCase()}</label>
              <p>N/A</p>
            </div>
          )
        }
        if (Array.isArray(object[key])) {
          if (object[key].length === 0) {
            return (
              <div className="deal_field">
                <label>{key.toUpperCase()}</label>
                <p>N/A</p>
              </div>
            )
          }
          if (typeof object[0] === 'object') {
            // Do something here
            return (
              <div className="field__section">
                <label>{key.toUpperCase()}</label>
                <div className="inner_fields">
                {Object.keys(object[key]).map((dealKey) => {
                    return handleValues(dealKey, object[key]);
                  })}
                </div>
              </div>
            )
          } else {
            return (
              <div className="deal_field">
                <label>{key.toUpperCase()}</label>
                <div>
                  {object[key].join(', ')}
                </div>
              </div>
            )
          }
        }
        return (
          <div className="field__section">
            <label>{key.toUpperCase()}</label>
            <div className="inner_fields">
              {Object.keys(object[key]).map((dealKey) => {
                return handleValues(dealKey, object[key]);
              })}
            </div>
          </div>
        )
      default:
        return (
          <div className="deal_field">
            <label>{key.toUpperCase()}</label>
            <p>{(object)[key] || 'N/A'}</p>
          </div>
        )
    }
  }

  const gatherFields = () => {
    return Object.keys(deal).map((dealKey) => {
      return handleValues(dealKey, deal);
    })
  }

  return (
    <div className="form__container">
      <div className="form_header__container">
        <h2>Deal #{deal.id}</h2>
        <button className="close" onClick={onClose} />
      </div>
      <div className="deal_fields__container">
        {gatherFields()}
      </div>
    </div>
  )
}

export default DealModal;