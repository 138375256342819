import React, { useEffect } from "react"
import NumberOptionsField from "./NumberOptionsField/NumberOptionsField"

const RadioText = ({ fieldItem, dealErrors, onChange, value }: any) => {
  useEffect(() => {
    if (fieldItem.default) {
      onChange(fieldItem.default)
    }
  }, [])

  return (
    <div
      key={fieldItem.keyName}
      className={`fieldContainer radioContainer ${
        dealErrors &&
        (dealErrors[fieldItem.keyName] ||
          dealErrors[`${fieldItem.keyName}_string`])
          ? "error"
          : ""
      }`}
    >
      <label>
        <b>
          {fieldItem.label}
          {fieldItem.required && " *"}
        </b>
      </label>

      <div
        className="radioOptionsContainer"
        onChange={(event: any) => onChange(event.target.value)}
      >
        {fieldItem.options.map((option: string, index: number) => (
          <NumberOptionsField
            type="text"
            key={`radio-text-${fieldItem.keyName}-${index}`}
            fieldItem={fieldItem}
            option={option}
            selected={value === option}
          />
        ))}
      </div>
    </div>
  )
}

export default RadioText
