import React, { useEffect } from "react"
import './index.scss';

const TextField = ({ fieldItem, dealErrors, onChange, value }: any) => {
  useEffect(() => {
    if (fieldItem.default) {
      onChange(fieldItem.default)
    }
  }, [fieldItem])

  return (
    <div
      className={`fieldContainer ${
        dealErrors &&
        (dealErrors[fieldItem.keyName] ||
          dealErrors[`${fieldItem.keyName}_string`])
          ? "error"
          : ""
      }`}
    >
      <label>
        <b>
          {fieldItem.label}
          {fieldItem.required && " *"}
        </b>
      </label>

      <input
        data-testid={fieldItem.keyName}
        className="defaultInput"
        value={value}
        type={fieldItem.type}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  )
}

export default TextField
