import React from 'react';
import DefaultButton from '../../../../Buttons/DefaultButton';
import DefaultCard from '../../../../Cards/DefaultCard';
import { MessageThread } from '../../../Conversations/types/MessageThread';
import './index.scss';

type SMSCardProps = {
  messageThread: MessageThread,
}
const SMSHistoryCard: React.FC<SMSCardProps> = ({ messageThread }) => {

  const handleClick = () => {
  
  }

  const showThread = () => {

  }

  return (
    <DefaultCard selected={false} highlighted onClick={handleClick}>
      <div className="sms_history_card__container">
        <div className="sms_header__container">
          <h5>{messageThread.contacts[0]} &lt;-&gt; {messageThread.contacts[1]}</h5>
          {messageThread.archive && (<p>ARCHIVED</p>)}
        </div>
        <DefaultButton className="small__button" text="Show Conversation" callback={showThread} />
      </div>
    </DefaultCard>
  )
}

export default SMSHistoryCard;