import React from 'react';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import { selectSelectedMessageThread } from '../../../../../store/Conversation/selector';
import DefaultCard from '../../../../Cards/DefaultCard';
import NotificationBadge from '../../../../NotificationBadge/NotificationBadge';
import { MessageThread } from '../../types/MessageThread';
import './index.scss';

type ConversationCardProps = {
  messageThread: MessageThread, // TODO: change this to the conversation type when received
  handleClick: (messageThread: MessageThread) => any,
}

const CustomerConversationCard: React.FC<ConversationCardProps> = ({ messageThread, handleClick }) => {
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread;
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, 'MM/dd/yyyy HH:mm aa')
  }
  return (
    <DefaultCard selected={selectedMessageThread && selectedMessageThread.id === messageThread.id} highlighted={true /* TODO: check message count when available */} onClick={() => handleClick(messageThread)}>
      <div className="customer_card__container">
        <div className="customer_card__top_section">
          <div className="customer_card__name_badge">
            <h5>{messageThread.customers[0].firstname} {messageThread.customers[0].lastname}</h5>
            {/* TODO: GET MESSAGE COUNT */}
            {/* {messageThread.latest_message.delivered_at === null && <NotificationBadge count={1} />} */}
          </div>
          <div className="customer_card__timestamp">
            <p>{messageThread && messageThread.latest_message ? formatDate(messageThread.latest_message.sent_at) : ''}</p>
          </div>
        </div>
        <p className="customer_card__message">{messageThread && messageThread.latest_message ? messageThread.latest_message.body : ''}</p>
        <p className="customer_card__assigned_to">{messageThread.assigned_to ? `ASSIGNED TO: ${messageThread.assigned_to.first_name.toUpperCase()}` : messageThread.team ? `ASSIGNED TO TEAM #${messageThread.team}` : 'NOT ASSIGNED'}</p>
      </div>
    </DefaultCard>
  )
}

export default CustomerConversationCard;
