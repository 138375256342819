import React, { useEffect } from 'react';
import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { selectConversations, selectSelectedMessageThread } from '../../store/Conversation/selector';
import { MessageThread } from '../Dashboard/Conversations/types/MessageThread';
// import { updateMessages } from '../../store/Message/reducer';
import { fetchMessagesFromConversationId, MessagesProps } from '../../store/Message/actions';
import { updateMessages } from '../../store/Message/reducer';
import { Conversation } from '../Dashboard/Conversations/types/Conversation';
import { updateConversations } from '../../store/Conversation/reducer';
import { fetchConversations } from '../../store/Conversation/actions';

type ConversationProps = {
  channelId: string,
}
let count = 0;
const useConversationHook = ({ channelId }: ConversationProps) => {
  const dispatch = useAppDispatch();
  const conversations = useSelector((state) => selectConversations(state)) as Conversation
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread;
  let pusher: Pusher;
  let channel: PusherTypes.Channel;
  const setUpChannels = () => {
    channel = pusher.subscribe('conversations');
    
    channel.bind('message', (data: MessageThread) => {
      if (data && data.id === selectedMessageThread.id) {
        dispatch(updateMessages(data.latest_message))
        // Update Conversations side bar with updated message
        const newResults = conversations.results.map((item) => {
          if (item.id === data.id) {
            return data;
          } else {
            return item;
          }
        })
        const newConvo = Object.assign({}, conversations);
        newConvo.results = newResults;
        dispatch(updateConversations(newConvo));
        dispatch(fetchConversations(1));
      }
      // What comes back here:
      // {
      //   "id": "b1f4d6d0-be09-6c51-7844-2e528904dec6",
      //   "assigned_to": null,
      //   "customers": [
      //     {
      //       "acquisition_channel": null,
      //       "buying_market": {
      //         "address": "6310 James Lane\nEdwardsstad, TN 53688",
      //         "latitude": "-7.073912",
      //         "longitude": "-174.854102",
      //         "street_number": null,
      //         "street_name": "Wilson Street",
      //         "unit_number": "239",
      //         "city": "Kathleenhaven",
      //         "region": "eJltTWWvkgawrnUunUTz",
      //         "state": "WA",
      //         "country": "US",
      //         "zipcode": {
      //           "zipcode": "98058",
      //           "city": "Seattle",
      //           "region": "King County",
      //           "state": "WA",
      //           "latitude": "-81.219827000000000",
      //           "longitude": "138.305041000000000"
      //         }
      //       },
      //       "buying_price_high": "456.00",
      //       "buying_price_low": "123.00",
      //       "email": "contact308@test.com",
      //       "firstname": "Joanne",
      //       "id": 308,
      //       "initial_mortgage_status": "N/A",
      //       "lastname": "Miller",
      //       "lead_status_id": null,
      //       "phone": "+15555539898",
      //       "searches": [],
      //       "tags": [],
      //       "trustpilot_link": null,
      //       "vendor": "1"
      //     }
      //   ],
      //   "latest_message": {
      //     "id": 58,
      //     "sent_at": "2022-03-04T10:57:20.324912-06:00",
      //     "delivered_at": "2022-03-04T10:57:20.324682-06:00",
      //     "errored_at": null,
      //     "body": "Hello there Joanne Miller",
      //     "message_uid": null,
      //     "status_callback": "",
      //     "error": {},
      //     "to": "+15555539898",
      //     "sender": "+15672299569"
      //   },
      //   "sms_provider": 1,
      //   "slack_channel": "#customer-intros",
      //   "channel": null,
      //   "unenroll_field": null,
      //   "archive": false,
      //   "unenroll_fields": [],
      //   "team": 3,
      //   "contacts": [
      //     "+15555539898",
      //     "+15672299569"
      //   ]
      // }
    })
    channel.bind('new', (data: any) => {
      console.log("New Conversation Started")
      console.log({ data })
    })
    channel.bind('assign_to_user', (data: any) => {
      console.log("Conversation assigned to new user")
      console.log({ data })

    })
    channel.bind('assign_to_team', (data: any) => {
      console.log("Conversation assigned to a team")
      console.log({ data })
    })
  }

  useEffect(() => {
    const key: string = process.env.GATSBY_PUSHER_APP_KEY || '';
    const cluster: string = process.env.GATSBY_CLUSTER_ID || '';
    pusher = new Pusher(key, {
      cluster: cluster,
    })
    setUpChannels();

    return () => {
      channel.unbind_all();
      pusher.disconnect();
    }
  }, [channelId])

  return;
}

export default useConversationHook;