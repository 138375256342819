import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store';
import { fetchCallsFromCustomerId } from '../../../../store/Calls/actions';
import { selectCallHistory } from '../../../../store/Calls/selector';
import { selectSelectedMessageThread } from '../../../../store/Conversation/selector';
import { CallResults } from '../../../types/Calls';
import { MessageThread } from '../../Conversations/types/MessageThread';
import CallCard from './CallCard';
import './index.scss';

const CallWidget = () => {
  const dispatch = useAppDispatch();
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread;
  const callHistory = useSelector((state) => selectCallHistory(state));
  
  useEffect(() => {
    if (selectedMessageThread) {
      dispatch(fetchCallsFromCustomerId({ vid: selectedMessageThread.customers[0].id }))
    }
  }, [selectedMessageThread]);
  
  return (
    <div className="call_widget__container">
      <div className="calls__container">
      {callHistory && callHistory.results.length > 0 && callHistory.results.map((result: CallResults, index: number) => (
        <CallCard key={`callcard-${index}`} call={result} />
      ))}
      {(!callHistory || callHistory.results.length === 0) && <p className="no_call_history">There is no call history.</p>}
      </div>
    </div>
  )
}

export default CallWidget;
