import React, { useState } from 'react';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { AgreementSignedFields, AwaitingPaymentFields, ClosedFields, CommunicatingFields, LostFields, MeetingScheduledFields, MetHouseHuntedFields, NotConnectedFields, OnMarketMakingOffersFields, UnderContractFields, UnderpaidFields, UpdateStageOptions, UpdateStageTypes } from './Fields';
import { FieldTypes } from '../../../utils/fieldTypes';
import { Date, Dropdown, RadioNumber, RadioText, TextArea, TextField } from '../../FormBuilder/components';

interface FormBuilderInterface {
  selectedUpdateStage: number | null,
};

const useFormBuilderHook = ({
  selectedUpdateStage,
}: FormBuilderInterface) => {
  // return fields and other data
  const [data, setData] = useState({}) as any;

  const handleOptionChange = (key: string, value: any) => {
    const updatedField = Object.assign({ ...data }, {});
    updatedField[key] = value.value;
    setData(updatedField)
  }

  const updateFieldWithValue = (key: string, value: string | number) => {
    const updatedField = Object.assign({}, { ...data })
    updatedField[key] = value;
    setData(updatedField)
  }

  const updateRadio = (e: any, keyName: string) => {
    const updatedField = Object.assign({}, { ...data });
    updatedField[keyName] = e.target.value;
    setData(updatedField);
  }

  const handleDateChange = (val: Date, keyName: string, dateFormat: string) => {
    const formattedDate = format(val, dateFormat);
    const updatedField = Object.assign({}, { ...data });
    updatedField[keyName] = formattedDate;
    setData(updatedField);
  }

  const getJSXFieldElement = (fieldItem: any) => {
    // if (Object.keys(data).length === 0) {
    //   return;
    // }
    switch (fieldItem.type) {
      case FieldTypes.number:
      case FieldTypes.textfield:
        return (
          <TextField
            fieldItem={fieldItem}
            onChange={(value: string) =>
              setData((data: any) =>
                Object.assign({ ...data, [fieldItem.keyName]: value })
              )
            }
            value={data[fieldItem.keyName] || ""}
            key={fieldItem.keyName}
          />
        );
      case FieldTypes.textarea:
        return (
          <TextArea
            fieldItem={fieldItem}
            onChange={(value: string) =>
              setData((data: any) =>
                Object.assign({ ...data, [fieldItem.keyName]: value })
              )
            }
            value={data[fieldItem.keyName] || ""}
            key={fieldItem.keyName}
          />
        )
        case FieldTypes.radioNumber:
      return (
        <RadioNumber
          fieldItem={fieldItem}
          onChange={(value: string) =>
            setData((data: any) =>
              Object.assign({ ...data, [fieldItem.keyName]: value })
            )
          }
          value={data[fieldItem.keyName] || ""}
          key={fieldItem.keyName}
        />
      )
    case FieldTypes.radioText:
      return (
        <RadioText
          fieldItem={fieldItem}
          onChange={(value: string) =>
            setData((data: any) =>
              Object.assign({ ...data, [fieldItem.keyName]: value })
            )
          }
          value={data[fieldItem.keyName] || ""}
          key={fieldItem.keyName}
        />
      )
    case FieldTypes.date:
      return (
        <Date
          fieldItem={fieldItem}
          onChange={(value: string) =>
            setData((data: any) =>
              Object.assign({ ...data, [fieldItem.keyName]: value })
            )
          }
          value={data[fieldItem.keyName] || ""}
          key={fieldItem.keyName}
        />
      )
    case FieldTypes.header:
      return (
        <h3 key={fieldItem.keyName} className="fieldHeader">
          {fieldItem.label}
        </h3>
      )
      default:
        return (
          <Dropdown
            fieldItem={fieldItem}
            onChange={(value: string) =>
              setData((data: any) =>
                Object.assign({ ...data, [fieldItem.keyName]: value })
              )
            }
            value={data[fieldItem.keyName] || ""}
            key={fieldItem.keyName}
          />
        )
    }
  }

  const getFieldsFromSelectedUpdateStage = () => {
    if (selectedUpdateStage) {
      // get string type from number
      const selectedStage = UpdateStageOptions.filter((option) => {
        return option.value === selectedUpdateStage;
      })
      if (selectedStage.length > 0) {
        switch (selectedStage[0].label) {
          case UpdateStageTypes['Agreement Signed']:
            return AgreementSignedFields
          case UpdateStageTypes['Awaiting Payment']:
            return AwaitingPaymentFields;
          case UpdateStageTypes.Closed:
            return ClosedFields;
          case UpdateStageTypes.Communicating:
            return CommunicatingFields;
          case UpdateStageTypes.Lost:
            return LostFields;
          case UpdateStageTypes['Meeting Scheduled']:
            return MeetingScheduledFields;
          case UpdateStageTypes['Met / House Hunted']:
            return MetHouseHuntedFields;
          case UpdateStageTypes['Not Connected']:
            return NotConnectedFields;
          case UpdateStageTypes['On Market / Making Offers']:
            return OnMarketMakingOffersFields;
          case UpdateStageTypes['Under Contract']:
            return UnderContractFields;
          default:
            return UnderpaidFields;
        }
      }
      
    } else {
      return [];
    }
  }

  const getFields = () => {
    const fieldsFromUpdateStage = getFieldsFromSelectedUpdateStage() as any;
    const fieldsJSX = fieldsFromUpdateStage.map((field: any) => {
      return getJSXFieldElement(field);
    })
    return fieldsJSX;
  }
  return [getFields(), data]
}

export default useFormBuilderHook;
