import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './index.scss';
import { useAppDispatch } from '../../../../store';
import { selectSelectedMessageThread } from '../../../../store/Conversation/selector';
import { fetchDealsFromCustomerId } from '../../../../store/Deals/actions';
import { selectDeals } from '../../../../store/Deals/selector';
import { Deals } from '../../../types/Deals';
import { MessageThread } from '../../Conversations/types/MessageThread';
import DealCard from './DealCard';

const DealsWidget = () => {
  const dispatch = useAppDispatch();
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread
  const deals = useSelector((state) => selectDeals(state)) as Deals
  useEffect(() => {
    if (selectedMessageThread) {
      const customerId = selectedMessageThread.customers[0].id;
      dispatch(fetchDealsFromCustomerId({ vid: customerId }))
    }
  }, [selectedMessageThread])

  return (
    <div className="deal_widget__container">
      <div className="deals__container">
        {deals && deals.results.length > 0 && deals.results.map((result, index) => (
          <DealCard key={`dealcard-${index}`} deal={result} />
        ))}
        {(!deals || deals.results.length === 0) && <p className="no_history">There is no deal history.</p>}
      </div>
    </div>
  )
}

export default DealsWidget;