import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store';
import { openContactModal } from '../../../../store/Contacts/reducer';
import { archiveConversation, ArchiveProps } from '../../../../store/Conversation/actions';
import { selectSelectedMessageThread } from '../../../../store/Conversation/selector';
import DefaultButton from '../../../Buttons/DefaultButton';
import OptionsButton, { Option } from '../../../Buttons/OptionsButton/OptionsButton';
import { MessageThread } from '../types/MessageThread';
import './index.scss';

export enum ContactOptionTypes {
  Team = "Team",
  User = "User",
}

const options = [
  {
    title: 'Assign To Team',
    id: ContactOptionTypes.Team,
  },
  {
    title: 'Assign To User',
    id: ContactOptionTypes.User,
  }
] as Option[]

const ContactNavBar = () => {
  const dispatch = useAppDispatch();
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread;

  const handleArchive = () => {
    dispatch(archiveConversation({ id: selectedMessageThread.id } as ArchiveProps))
  }

  const handleOptions = (option: Option) => {
    dispatch(openContactModal(option.id))
  }

  return (
    <div className="contact__navbar">
      <div className="contact__navbar___info">
        <h4>{selectedMessageThread && selectedMessageThread.customers.length > 0 ? `${selectedMessageThread.customers[0].firstname} ${selectedMessageThread.customers[0].lastname}` : ''}</h4>
        <p>#{selectedMessageThread && selectedMessageThread.customers.length > 0 ? `${selectedMessageThread.customers[0].id}` : ''}</p>
      </div>
      <div className="contact__navbar___buttons">
        {!selectedMessageThread.archive && ( <DefaultButton text="Archive" className="red__button" callback={handleArchive} /> )}
        {selectedMessageThread.archive && <p className="archived">ARCHIVED</p>}
        <OptionsButton options={options} optionClick={(option) => handleOptions(option)} />
      </div>
    </div>
  )
}

export default ContactNavBar;
