import React, { useState } from 'react';
import { useAppDispatch } from '../../../../../store';
import OptionsButton from '../../../../Buttons/OptionsButton/OptionsButton';
import SendButton from '../../../../Buttons/SendButton';
import MessageInput from '../../../../Inputs/MessageInput/MessageInput';
import { sendMessage, SendMessageProps } from '../../../../../store/Message/actions';
import './index.scss';
import { useSelector } from 'react-redux';
import { selectSelectedMessageThread } from '../../../../../store/Conversation/selector';
import { MessageThread } from '../../types/MessageThread';
import DropdownArrowButton from '../../../../Buttons/DropdownArrowButton';
import ModalContainer from '../../../../Modals/ModalContainer';
import CustomTimeModal from '../CustomTimeModal';

const options: any[] = []

const MessageBar = () => {
  const dispatch = useAppDispatch();
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread;
  const [message, setMessage] = useState('');
  const [showCustomTime, setShowCustomTime] = useState(false);

  const handleOnChange = (e: any) => {

    setMessage(e.target.value);
  }

  const handleOnKeyChange = (e: any) => {
    // TODO: keep this if down the road it changes.
    // if (e.key === 'Enter' && message !== '') {
    //   const messageProps = {
    //     message: message,
    //     send_at: null, 
    //     id: selectedMessageThread.id
    //   } as SendMessageProps
    //   e.preventDefault();
    //   dispatch(sendMessage(messageProps))
    //   setMessage('');
    // }
  }

  const enterMessage = (delay?: Date | null) => {
    // send_at iso 8601 format -> data.toISOString()
    if (message !== '') {
      const messageProps = {
        message: message,
        send_at: delay ? delay.toISOString() : null,
        id: selectedMessageThread.id
      } as SendMessageProps
      dispatch(sendMessage(messageProps))
      setMessage('');
    }
  }

  const handleOptions = (option: any) => {
   // TODO if we add images or whatever
  }

  const getOptions = () => {
    return [
      {
        title: 'Schedule Message',
        type: 'title',
        id: 'schedule-message'
      },
      {
        title: 'Custom time',
        id: 'CUSTOM_SEND_TIME',
      }
  ]
  }

  const handleDropdownOption = (option: any) => {
    if (option.id === "CUSTOM_SEND_TIME") {
      setShowCustomTime(true);
    }
  }

  const onCustomTimeClose = () => {
    setShowCustomTime(false);
  }

  const setDate = (date: Date) => {
    setShowCustomTime(false);
    enterMessage(date);
  }

  return (
    <>
      <div className="message_bar__container">
        <OptionsButton optionClick={(option) => handleOptions(option)} options={options} topPosition />
        <MessageInput
          onKeyPress={handleOnKeyChange}
          onChange={handleOnChange}
          placeholder="Enter your message"
          value={message}
        />
        {/* SEND BUTTON */}
        <div className="send_container">
          <SendButton onClick={() => enterMessage(null)} />
          <div className="vertical_separator" />
          <DropdownArrowButton disabled={!message} optionClick={(option) => handleDropdownOption(option)} options={getOptions()} topPosition />
        </div>
      </div>
      {showCustomTime && (
        <ModalContainer>
          <CustomTimeModal onClose={onCustomTimeClose} setDate={(date) => setDate(date)} />
        </ModalContainer>
      )}
    </>
  )
}

export default MessageBar;
