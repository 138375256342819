import React, { useEffect } from "react"
import DatePicker from "react-datepicker"
import { format } from "date-fns"
import { convertUTCToLocalDateObject, getUTCDate } from "../../../utils/helpers"

const Date = ({ fieldItem, dealErrors, onChange, value }: any) => {
  useEffect(() => {
    if (fieldItem.default) {
      onChange(fieldItem.default)
    }
  }, [])

  return (
    <div
      key={fieldItem.keyName}
      className={`fieldContainer dateContainer ${
        dealErrors &&
        (dealErrors[fieldItem.keyName] ||
          dealErrors[`${fieldItem.keyName}_string`])
          ? "error"
          : ""
      }`}
    >
      <label>
        <b>
          {fieldItem.label}
          {fieldItem.required && " *"}
        </b>
      </label>
      <DatePicker
        data-testid={fieldItem.keyName}
        className="datepicker"
        minDate={fieldItem.minDate || null}
        selected={value ? convertUTCToLocalDateObject(value) : null}
        onChange={(val: any) =>
          onChange(
            fieldItem.isEpoch
              ? getUTCDate(val)
              : format(val, fieldItem.format || "MMM dd, yyyy")
          )
        }
      />
    </div>
  )
}

export default Date
