import React from 'react';
import './index.scss';

const ConversationsContainer: React.FC = ({ children }) => {

  return (
    <div className="conversations__container">
      {children}
    </div>
  )
}

export default ConversationsContainer;
