import React from 'react';
import { SVGProps } from './types';

const StarIcon: React.FC<SVGProps> = ({ onClick }) => (
  <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.52557 0.863163C7.67736 0.406966 8.32264 0.406967 8.47443 0.863164L10.1246 5.82283H15.4312C15.9184 5.82283 16.1179 6.44868 15.7205 6.73062L11.4377 9.76933L13.0799 14.7051C13.2325 15.1637 12.7103 15.5504 12.3162 15.2708L8 12.2084L3.68382 15.2708C3.28966 15.5504 2.76749 15.1637 2.92006 14.7051L4.56231 9.76933L0.279457 6.73062C-0.117914 6.44868 0.0815557 5.82283 0.568785 5.82283H5.87539L7.52557 0.863163Z" fill="#A0A1A5"/>
  </svg>
)

export default StarIcon;
