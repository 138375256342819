import React from 'react';
import './Search.scss';
import SearchIcon from '../../Icons/SearchIcon';
import { filterBySearch } from '../../../store/Conversation/actions';
import { useAppDispatch } from '../../../store';
import ClearSeachIcon from '../../Icons/ClearSearchIcon';

type SearchProps = {
  onBlur: (e: any) => void,
  onChange: (e: any) => void,
  placeholder: string,
  value: string,
}

const Search: React.FC<SearchProps> = ({ onBlur, onChange, placeholder, value }) => {
  const dispatch = useAppDispatch();

  const search = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    dispatch(filterBySearch({ searchValue: e.currentTarget.value }));
  }

  const clearSearch = () => {
    onChange({ currentTarget: { value: '' } });
    dispatch(filterBySearch({ searchValue: '' }));
  }

  return (
    <div className="search-field">
      <input onBlur={onBlur} onChange={e => search(e)} value={value} placeholder={placeholder} />
      {value.length > 0 ? <ClearSeachIcon onClick={clearSearch} />  : <SearchIcon />}
    </div>
  )
}

export default Search;