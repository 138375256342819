import React from 'react';

const SearchIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1 5.08799C1 2.8339 2.8338 1 5.08799 1C7.34216 1 9.17597 2.8339 9.17599 5.08797C9.17599 7.34207 7.34216 9.17597 5.08799 9.17597C2.8338 9.17597 1 7.34207 1 5.08799ZM1.40881 5.08799C1.40881 7.11672 3.05936 8.76718 5.08799 8.76718C7.11661 8.76718 8.76718 7.11671 8.76718 5.08799C8.76718 3.05928 7.11661 1.40881 5.08799 1.40881C3.05936 1.40881 1.40881 3.05928 1.40881 5.08799ZM8.27348 8.35833L8.56251 8.06931L11 10.5068L10.711 10.7958L8.27348 8.35833Z" fill="#7C7C7C"/>
    <path d="M9.17599 5.08797L9.67599 5.08797L9.67599 5.08797L9.17599 5.08797ZM8.56251 8.06931L8.91606 7.71575L8.56251 7.3622L8.20895 7.71575L8.56251 8.06931ZM8.27348 8.35833L7.91993 8.00478L7.56638 8.35833L7.91993 8.71188L8.27348 8.35833ZM11 10.5068L11.3536 10.8604L11.7071 10.5068L11.3536 10.1532L11 10.5068ZM10.711 10.7958L10.3574 11.1494L10.711 11.5029L11.0645 11.1494L10.711 10.7958ZM5.08799 0.5C2.55765 0.5 0.5 2.55777 0.5 5.08799H1.5C1.5 3.11003 3.10995 1.5 5.08799 1.5V0.5ZM9.67599 5.08797C9.67596 2.55777 7.61831 0.5 5.08799 0.5V1.5C7.06601 1.5 8.67597 3.11003 8.67599 5.08798L9.67599 5.08797ZM5.08799 9.67597C7.61831 9.67597 9.67599 7.6182 9.67599 5.08797H8.67599C8.67599 7.06593 7.06601 8.67597 5.08799 8.67597V9.67597ZM0.5 5.08799C0.5 7.6182 2.55765 9.67597 5.08799 9.67597V8.67597C3.10995 8.67597 1.5 7.06593 1.5 5.08799H0.5ZM5.08799 8.26718C3.33549 8.26718 1.90881 6.84057 1.90881 5.08799H0.908805C0.908805 7.39288 2.78322 9.26718 5.08799 9.26718V8.26718ZM8.26718 5.08799C8.26718 6.84055 6.84047 8.26718 5.08799 8.26718V9.26718C7.39274 9.26718 9.26718 7.39286 9.26718 5.08799H8.26718ZM5.08799 1.90881C6.84047 1.90881 8.26718 3.33543 8.26718 5.08799H9.26718C9.26718 2.78313 7.39274 0.908805 5.08799 0.908805V1.90881ZM1.90881 5.08799C1.90881 3.33543 3.33549 1.90881 5.08799 1.90881V0.908805C2.78322 0.908805 0.908805 2.78313 0.908805 5.08799H1.90881ZM8.20895 7.71575L7.91993 8.00478L8.62704 8.71188L8.91606 8.42286L8.20895 7.71575ZM11.3536 10.1532L8.91606 7.71575L8.20895 8.42286L10.6464 10.8604L11.3536 10.1532ZM11.0645 11.1494L11.3536 10.8604L10.6464 10.1532L10.3574 10.4423L11.0645 11.1494ZM7.91993 8.71188L10.3574 11.1494L11.0645 10.4423L8.62704 8.00478L7.91993 8.71188Z" fill="#7C7C7C"/>
  </svg>
)

export default SearchIcon;
