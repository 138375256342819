import React from 'react';
import './index.scss';
const ModalContainer: React.FC = ({ children }) => (
  <div className="modal">
    <div className="modal__container">
      {children}
    </div>
  </div>
)

export default ModalContainer;
