import React, { useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns-tz';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DefaultButton from '../../../../Buttons/DefaultButton';
import './index.scss';

type CustomTimeModalProps = {
  onClose: () => void,
  setDate: (date: Date) => void,
}

const CustomTimeModal: React.FC<CustomTimeModalProps> = ({ onClose, setDate }) => {
  const [value, setValue] = useState<Date | null>(new Date());
  
  return (
    <div className="custom_time__container">
      <div className="form_header__container">
        <h2>Set Custom Time</h2>
        <button className="close" onClick={onClose} />
      </div>
      <p><strong>Please Note:</strong> The time you select is in your current timezone ({format(new Date(), 'zzz')}).</p>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          label="DateTimePicker"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
        />
      </LocalizationProvider>
      <DefaultButton text="Set Time" callback={() => setDate(value!)} disabled={!value}  />
    </div>
  )
}

export default CustomTimeModal;

