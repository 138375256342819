import React, { Fragment, useEffect, useState } from "react"
import { FieldTypes } from "../../../utils/fieldTypes"
import "react-datepicker/dist/react-datepicker.css"
import {
  Date,
  Dropdown,
  RadioText,
  RadioNumber,
  TextArea,
  TextField,
} from "../components"

interface DefaultFormBuilderInterface {
  config: any[] | null, // Array of field configurations
  defaultData: any,
}

const useDefaultFormBuilder = ({ config, defaultData }: DefaultFormBuilderInterface) => {
  const [data, setData] = useState({}) as any

  const deleteFieldValue = (key: string) => {
    const updatedField = Object.assign({}, { ...data })

    if (updatedField[key]) {
      delete updatedField[key]
      setData(updatedField)
    }
  }

  // Check field for data triggers
  const checkFieldCriteria = (data: any, fieldItem: any) => {
    const { triggers } = fieldItem

    // If no triggers key then its just a default field;
    if (!triggers) {
      return true
    }

    const tests = Object.keys(triggers).every(trigger => {
      const test = triggers[trigger]
      const storedValue = data[trigger]

      if (!storedValue || storedValue === null || storedValue === "") {
        // No key in the data or empty data set
        return false
      }

      // Check if the value is a function and pass the value
      if (typeof test === "function") {
        return test(storedValue)
      }

      // Check if one of the Trigger values is in the data
      if (Array.isArray(test)) {
        return test.some(value => storedValue === value)
      }

      if (typeof test === "string") {
        return storedValue === test
      }

      return false
    })

    return tests
  }

  const getJSXFieldElement = (fieldItem: any) => {
    if (!checkFieldCriteria(data, fieldItem)) {
      deleteFieldValue(fieldItem.keyName)
      return <Fragment key={fieldItem.keyName}></Fragment>
    }

    switch (fieldItem.type) {
      case FieldTypes.number:
      case FieldTypes.textfield:
        return (
          <TextField
            fieldItem={fieldItem}
            onChange={(value: string) =>
              setData((data: any) =>
                Object.assign({ ...data, [fieldItem.keyName]: value })
              )
            }
            value={data[fieldItem.keyName] || ""}
            key={fieldItem.keyName}
          />
        )
      case FieldTypes.textarea:
        return (
          <TextArea
            fieldItem={fieldItem}
            onChange={(value: string) =>
              setData((data: any) =>
                Object.assign({ ...data, [fieldItem.keyName]: value })
              )
            }
            value={data[fieldItem.keyName] || ""}
            key={fieldItem.keyName}
          />
        )
      case FieldTypes.dropdown:
        return (
          <Dropdown
            fieldItem={fieldItem}
            onChange={(value: string) =>
              setData((data: any) =>
                Object.assign({ ...data, [fieldItem.keyName]: value })
              )
            }
            value={data[fieldItem.keyName] || ""}
            key={fieldItem.keyName}
          />
        )
      case FieldTypes.radioNumber:
        return (
          <RadioNumber
            fieldItem={fieldItem}
            onChange={(value: string) =>
              setData((data: any) =>
                Object.assign({ ...data, [fieldItem.keyName]: value })
              )
            }
            value={data[fieldItem.keyName] || ""}
            key={fieldItem.keyName}
          />
        )
      case FieldTypes.radioText:
        return (
          <RadioText
            fieldItem={fieldItem}
            onChange={(value: string) =>
              setData((data: any) =>
                Object.assign({ ...data, [fieldItem.keyName]: value })
              )
            }
            value={data[fieldItem.keyName] || ""}
            key={fieldItem.keyName}
          />
        )
      case FieldTypes.date:
        return (
          <Date
            fieldItem={fieldItem}
            onChange={(value: string) =>
              setData((data: any) =>
                Object.assign({ ...data, [fieldItem.keyName]: value })
              )
            }
            value={data[fieldItem.keyName] || ""}
            key={fieldItem.keyName}
          />
        )
      case FieldTypes.header:
        return (
          <h3 key={fieldItem.keyName} className="fieldHeader">
            {fieldItem.label}
          </h3>
        )
      default:
        return <></>
    }
  }

  const getFields = () => {
    if (config) {
      return config.map((field: any) => getJSXFieldElement(field))
    }

    // No fields No dice
    return []
  }
  const [allFields, setAllFields] = useState(getFields())

  useEffect(() => {
    if (config) {
      setAllFields(getFields())
    }
  }, [config])

  useEffect(() => {
    if (data) {
      setAllFields(getFields())
    }
  }, [data])

  useEffect(() => {
    setData(defaultData)
  }, [defaultData])

  return [allFields, data]
}

export default useDefaultFormBuilder
