import * as React from 'react';
import PropTypes from 'prop-types';
import './DefaultButton.scss';
// do something with next()
interface DefaultButton {
  text: string;
  callback: (arg: any) => any;
  disabled: false;
  className: string;
}
const DefaultButton = ({ text, callback, disabled, className }: any) => (
  <button
    className={`${className || ''} default-button`}
    disabled={disabled}
    onClick={(next) => callback(next)}
    type="submit"
  >
    {text}
  </button>
);

DefaultButton.propTypes = {
  text: PropTypes.any,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

DefaultButton.defaultProps = {
  text: '',
  callback: () => null,
  disabled: false,
  className: '',
};

export default DefaultButton;
