import React, { useEffect } from 'react';

type OutsideAlerterProps = {
  ref: any,
  clickedOutside: () => void,
}
const useOutsideAlerter = ({ ref, clickedOutside }: OutsideAlerterProps) => {
  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        clickedOutside();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [ref])
}

export default useOutsideAlerter;