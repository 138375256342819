import React, { useState } from 'react';
import calculateNodeHeight from '../internal/calculateNodeHeight';
import getSizingData, { SizingData } from '../internal/getSizingData';
import './MessageInput.scss';
type MessageProps = {
  onChange: (e: any) => void,
  placeholder: string,
  value: string,
  onKeyPress: (e: any) => void,
}
const MAX_HEIGHT = 60;
const MessageInput: React.FC<MessageProps> = ({ placeholder, value, onChange, onKeyPress }) => {
  const [maxHeight, setMaxHeight] = useState(20);

  const handleChange = (e: any) => {
    const sizeData = getSizingData(e.target) as SizingData;
    const height = calculateNodeHeight(sizeData, e.target.value, 1, 3);

    if (height[0] > MAX_HEIGHT) {
      setMaxHeight(MAX_HEIGHT);
    } else {
      setMaxHeight(height[0]);
    }
    onChange(e);
  }

  return (
    <div className="message-input__container">
      <textarea
        style={{
          minHeight: maxHeight,
          maxHeight: maxHeight,
          borderRadius: maxHeight > 20 ? '8px' : '40px',
        }}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
    </div>
    
  )
}

export default MessageInput;
