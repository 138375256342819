import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store';
import { updateCustomer } from '../../../../store/Conversation/actions';
import { selectSelectedMessageThread } from '../../../../store/Conversation/selector';
import { FieldTypes } from '../../../../utils/fieldTypes';
import DefaultButton from '../../../Buttons/DefaultButton';
import useDefaultFormBuilder from '../../../FormBuilder/FormHook/defaultFormHook';
import { Customer } from '../types/Customer';
import { MessageThread } from '../types/MessageThread';
import './index.scss';

const config = [
  {
    type: FieldTypes.header,
    keyName: 'header',
    label: 'Customer Details'
  },
  {
    label: 'First Name',
    keyName: 'firstname',
    type: FieldTypes.textfield,
  },
  {
    label: 'Last Name',
    keyName: 'lastname',
    type: FieldTypes.textfield,
  },
  {
    label: 'Email',
    keyName: 'email',
    type: FieldTypes.textfield,
  },
  {
    label: 'Phone',
    keyName: 'phone',
    type: FieldTypes.textfield,
  },
  {
    label: 'Buying Price High',
    keyName: 'buying_price_high',
    type: FieldTypes.textfield,
  },
  {
    label: 'Buying Price Low',
    keyName: 'buying_price_low',
    type: FieldTypes.textfield,
  },
]


const CustomerInfo = () => {
  const dispatch = useAppDispatch();
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread;
  const [defaultProperties, setDefaultProperties] = useState<Customer | null>({} as any);
  const [fields, data] = useDefaultFormBuilder({ config, defaultData: defaultProperties });
  useEffect(() => {
    if (selectedMessageThread) {
      setDefaultProperties(selectedMessageThread.customers[0]);
    }
  }, [selectedMessageThread])

  const handleSave = () => {
    // TODO: Save Customer Info
    const newData = data;
    delete newData.acquisition_channel;
    delete newData.buying_market;
    delete newData.lead_status_id;
    delete newData.trustpilot_link;

    dispatch(updateCustomer({
      customer: {
        ...data,
        id: selectedMessageThread.customers[0].id,
      }
    }))

  }

  return (
    <div className="customer-info__container">
      {fields}
      <DefaultButton className="customer-info__button" text="Save" callback={handleSave} />
    </div>
  )
}

export default CustomerInfo;
