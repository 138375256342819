import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store';
import { fetchConversations } from '../../../../store/Conversation/actions';
import { setSelectedMessageThread } from '../../../../store/Conversation/reducer';
import { selectConversations, selectSelectedMessageThread } from '../../../../store/Conversation/selector';
import { selectWidget } from '../../../../store/Widget/reducer';
import DefaultButton from '../../../Buttons/DefaultButton';
import { Conversation } from '../types/Conversation';
import { MessageThread } from '../types/MessageThread';
import CustomerConversationCard from './CustomerConversationCard';
import './index.scss';
// TODO: remove sample customer
// import { SampleCustomer, SampleMessages } from './testData/data';

const numberPerResults = 10;

const CustomerConversationList = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread
  const conversations = useSelector((state) => selectConversations(state)) as Conversation

  useEffect(() => {
    dispatch(fetchConversations(1));
  }, [])

  const handleConversationSelect = (messageThread: MessageThread) => {
    // TODO: Sample here replace eventually
    dispatch(selectWidget(null));
    dispatch(setSelectedMessageThread(messageThread))
  }

  const showMoreConversations = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    dispatch(fetchConversations(nextPage));
  }

  return (
    <div className="customer-conversation__list_container">
      {conversations && conversations.results && conversations.results.map((messageThread, key) => (
        <CustomerConversationCard
          messageThread={messageThread}
          key={`messageThread.${messageThread.id}-${key}`}
          handleClick={(thread: MessageThread) => handleConversationSelect(thread)}
        />
      ))}
      {conversations && conversations.next !==  null && (<DefaultButton text="Show More" className="small__button" callback={showMoreConversations} />)}
    </div>
  )
}

export default CustomerConversationList;
