import React from 'react';
import { SVGProps } from './types';

const ConversationsIcon: React.FC<SVGProps> = ({onClick}) => (
  <svg onClick={onClick} width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8C0 3.58172 3.58172 0 8 0H28C32.4183 0 36 3.58172 36 8V27C36 31.4183 32.4183 35 28 35H8C3.58172 35 0 31.4183 0 27V8Z" fill="#229FE6" fillOpacity="0.1"/>
    <path d="M20.0691 11.9732C20.9939 12.553 21.6423 13.5342 21.7604 14.6731C22.1375 14.8493 22.5561 14.9506 22.9999 14.9506C24.62 14.9506 25.9331 13.6375 25.9331 12.0176C25.9331 10.3975 24.62 9.08441 22.9999 9.08441C21.3952 9.08491 20.0936 10.3746 20.0691 11.9732ZM18.1475 17.979C19.7676 17.979 21.0807 16.6656 21.0807 15.0457C21.0807 13.4259 19.7674 12.1127 18.1475 12.1127C16.5276 12.1127 15.2138 13.4261 15.2138 15.046C15.2138 16.6658 16.5276 17.979 18.1475 17.979ZM19.3917 18.1789H16.9028C14.8319 18.1789 13.1471 19.8639 13.1471 21.9348V24.9786L13.1549 25.0263L13.3645 25.0919C15.3408 25.7094 17.0578 25.9153 18.471 25.9153C21.2312 25.9153 22.8312 25.1283 22.9297 25.0782L23.1257 24.9791H23.1466V21.9348C23.1474 19.8639 21.4626 18.1789 19.3917 18.1789ZM24.2446 15.1508H21.7749C21.7482 16.1389 21.3263 17.0288 20.6592 17.6687C22.4999 18.2161 23.8468 19.923 23.8468 21.9393V22.8772C26.2853 22.7879 27.6905 22.0968 27.7831 22.0503L27.979 21.951H28V18.9062C28 16.8356 26.3152 15.1508 24.2446 15.1508ZM13.0006 14.9511C13.5744 14.9511 14.1083 14.7837 14.5606 14.4984C14.7044 13.5606 15.207 12.7412 15.9251 12.1831C15.9281 12.1282 15.9334 12.0738 15.9334 12.0184C15.9334 10.3983 14.62 9.08516 13.0006 9.08516C11.3803 9.08516 10.0674 10.3983 10.0674 12.0184C10.0674 13.6378 11.3803 14.9511 13.0006 14.9511ZM15.6348 17.6687C14.9709 17.032 14.5506 16.1469 14.5199 15.1648C14.4283 15.158 14.3377 15.1508 14.2444 15.1508H11.7556C9.68476 15.1508 8 16.8356 8 18.9062V21.9505L8.00774 21.9974L8.2174 22.0636C9.80282 22.5585 11.2178 22.7866 12.4468 22.8585V21.9393C12.4473 19.923 13.7936 18.2166 15.6348 17.6687Z" fill="#229FE6"/>
  </svg>

)

export default ConversationsIcon;
