import React, { useState, useRef } from 'react';
import cn from 'classnames';
import useOutsideAlerter from '../../Hooks/useOutsideAlerter';
import './index.scss';

export type Option = {
  title: string,
  id: any,
}

type DropdownArrowButtonProps = {
  onClick?: () => void,
  options?: Option[],
  optionClick?: (option: any) => void,
  topPosition?: boolean,
  disabled?: boolean
}
const DropdownArrowButton:React.FC<DropdownArrowButtonProps> = ({ onClick, options, optionClick, topPosition = false, disabled = false }) => {
  const ref = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  useOutsideAlerter({ ref: ref, clickedOutside: () => {
    setShowOptions(false);
  }});

  const optionClickHandler = (option: Option) => {
    if (optionClick) {
      optionClick(option)
      setShowOptions(false);
    }
  }

  const handleOptionType = (option: any) => {

    if (option.type === 'title') {
      return <h4 key={`${option.id}`}>{option.title}</h4>
    } else {
      return (
        <div key={`${option.id}`} className="arrow__item" onClick={() => optionClickHandler(option)}>
          <p>{option.title}</p>
        </div>
      )
    }
  }

  return (
    <div ref={ref} className="arrow__container">
      <button disabled={disabled} className="arrow__button" type="button" role="button" onClick={() => setShowOptions(!showOptions)}>
        <span />
        <span />
      </button>
      {showOptions && options && optionClick && (
        <div className={cn("arrow_selection__container", {['placed_top']:  topPosition})}>
          {options.map((option, index) => (
            handleOptionType(option)
          ))}
        </div>
      )}
    </div>
  )
}

export default DropdownArrowButton;