import React from 'react';
import { SVGProps } from './types';

const TrashIcon: React.FC<SVGProps> = ({ onClick }) => (
  <svg onClick={onClick} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3_80)">
      <path d="M14.9062 2.65794H11.8125V2.10863C11.8125 1.19849 11.057 0.460693 10.125 0.460693H7.875C6.94301 0.460693 6.1875 1.19849 6.1875 2.10863V2.65794H3.09375C2.31711 2.65794 1.6875 3.27279 1.6875 4.03122V5.12984C1.6875 5.43324 1.93932 5.67916 2.25 5.67916H15.75C16.0607 5.67916 16.3125 5.43324 16.3125 5.12984V4.03122C16.3125 3.27279 15.6829 2.65794 14.9062 2.65794ZM7.3125 2.10863C7.3125 1.80582 7.56492 1.55932 7.875 1.55932H10.125C10.4351 1.55932 10.6875 1.80582 10.6875 2.10863V2.65794H7.3125V2.10863Z" fill="#8F9091"/>
      <path d="M2.75488 6.77777C2.6545 6.77777 2.57452 6.85969 2.57931 6.9576L3.04337 16.469C3.08626 17.3493 3.82665 18.0387 4.72876 18.0387H13.271C14.1732 18.0387 14.9135 17.3493 14.9564 16.469L15.4205 6.9576C15.4253 6.85969 15.3453 6.77777 15.2449 6.77777H2.75488ZM11.2499 8.15105C11.2499 7.84756 11.5016 7.60174 11.8124 7.60174C12.1232 7.60174 12.3749 7.84756 12.3749 8.15105V15.2921C12.3749 15.5956 12.1232 15.8414 11.8124 15.8414C11.5016 15.8414 11.2499 15.5956 11.2499 15.2921V8.15105ZM8.4374 8.15105C8.4374 7.84756 8.68912 7.60174 8.9999 7.60174C9.31068 7.60174 9.5624 7.84756 9.5624 8.15105V15.2921C9.5624 15.5956 9.31068 15.8414 8.9999 15.8414C8.68912 15.8414 8.4374 15.5956 8.4374 15.2921V8.15105ZM5.6249 8.15105C5.6249 7.84756 5.87662 7.60174 6.1874 7.60174C6.49818 7.60174 6.7499 7.84756 6.7499 8.15105V15.2921C6.7499 15.5956 6.49818 15.8414 6.1874 15.8414C5.87662 15.8414 5.6249 15.5956 5.6249 15.2921V8.15105Z" fill="#8F9091"/>
    </g>
    <defs>
    <clipPath id="clip0_3_80">
      <rect width="18" height="17.578" fill="white" transform="translate(0 0.460693)"/>
    </clipPath>
    </defs>
  </svg>
)

export default TrashIcon;
