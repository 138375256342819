import React from 'react';
import './index.scss';
import { useAppDispatch } from '../../../../store';
import { selectWidget } from '../../../../store/Widget/reducer';
import BackButton from '../../../Buttons/BackButton';

type WidgetNavProps = {
  title: string,
}

const WidgetNavigation = ({ title }: WidgetNavProps) => {
  const dispatch = useAppDispatch();

  const handleBack = () => {
    dispatch(selectWidget(null));
  }

  return (
    <div className="widget_nav__container">
      <BackButton onBack={() => handleBack()} />
      <h4>{title}</h4>
    </div>
  )
}

export default WidgetNavigation;
