import React from 'react';
import './index.scss';
import ArchiveBox from '../../../../Icons/ArchiveBox';
import StarIcon from '../../../../Icons/StarIcon';
import TrashIcon from '../../../../Icons/TrashIcon';

type ButtonBarProps = {
  onArchiveClick: () => void,
  onStarClick: () => void,
  onTrashClick: () => void,
}

const ButtonBar: React.FC<ButtonBarProps> = ({
  onArchiveClick,
  onStarClick,
  onTrashClick,
}) => {

  return (
    <div className="button-bar">
      <ArchiveBox onClick={() => onArchiveClick()} />
      <StarIcon onClick={() => onStarClick()} />
      <TrashIcon onClick={() => onTrashClick()} />
    </div>
  )
}

export default ButtonBar;
