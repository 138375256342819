import React from 'react';
import { useAppDispatch } from '../../../../../store';
import { openDealModal, selectDeal } from '../../../../../store/Deals/reducer';
import { openUpdateModal } from '../../../../../store/UpdateConnection/reducer';
import DefaultButton from '../../../../Buttons/DefaultButton';
import DefaultCard from '../../../../Cards/DefaultCard';
import { DealResults } from '../../../../types/Deals';
import './index.scss';

type DealCardProps = {
  deal: DealResults
}

const DealCard = ({ deal }: DealCardProps) => {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    
  }

  const changeConnectionClick = (e: any) => {
    e.stopPropagation()
    dispatch(selectDeal(deal))
    dispatch(openUpdateModal(true));
  }

  const openDealInfoModal = (e: any) => {
    e.stopPropagation()
    dispatch(selectDeal(deal));
    dispatch(openDealModal(true));
  }

  return (
    <DefaultCard selected={false} highlighted onClick={handleClick}>
      <div className="deal_item__container">
        <div className="deal_header__container">
          <h5>#{deal.id}</h5>
          <h5>Transaction: {deal.transaction_type}</h5>
        </div>
        <div className="deal_address__container">
          {deal.transaction_type === 'Sell' || deal.transaction_type === 'Both' ? (
            <p>Listing Address: {deal.listing_address} {deal.unit_number ? `#${deal.unit_number}` : ''}</p>
          ) : ''}
          {deal.transaction_type === 'Buy' || deal.transaction_type === 'Both' ? (
            <p>Buying Market: {deal.buying_market}</p>
          ) : ''}
        </div>
        <div className="deal_model__container">
          <p>Model: {deal.commission_model_name}</p>
        </div>
        <div className="deal_agents__container">
          <p>Losing Agents: {deal.losing_agents && deal.losing_agents.length > 0 ? deal.losing_agents.map((agent) => `${agent.firstname} ${agent.lastname} ID: ${agent.vid}`).join(', ') : 'N/A'}</p>
          <p>Winning Agent: {deal.winning_agent ? `${deal.winning_agent.firstname} ${deal.winning_agent.firstname} ID: ${deal.winning_agent.vid}` : 'N/A'}</p>
        </div>
        <div className="deal_update_connection__container">
            <DefaultButton callback={changeConnectionClick} className="small_button" text="Update Connection" />
            <DefaultButton callback={openDealInfoModal} className="small_button" text="View All Info" />
        </div>
      </div>
    </DefaultCard>
  )
}

export default DealCard;
