import React, { useState } from 'react';
import Search from '../../../Inputs/Search/Search';
import ButtonBar from './ButtonBar/ButtonBar';
import './index.scss';

const ConversationsFilterBar: React.FC = () => {
  const [search, setSearch] = useState('');

  const onBlur = () => {

  }

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  }

  const onTrashClick = () => {
    // Handle trash click
  }

  const onStarClick = () => {
    // Handle star click
  }

  const onArchiveClick = () => {
    // handle archive
    
  }

  return (
    <div className="filter-bar">
      {/* Search */}
      <Search placeholder="Search..." onBlur={() => onBlur()} onChange={onChange} value={search} />
      {/* Buttons */}
      <ButtonBar
        onTrashClick={onTrashClick}
        onStarClick={onStarClick}
        onArchiveClick={onArchiveClick}
      />
    </div>
  )
}

export default ConversationsFilterBar;
