import React from 'react';
import './index.scss';

const SendIcon = () => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2_149)">
    <path d="M4.69513 9.6866V12.2375C4.69513 12.4157 4.80674 12.5735 4.97201 12.6296C5.01332 12.6434 5.05571 12.65 5.09757 12.65C5.22313 12.65 5.3444 12.5895 5.42167 12.4817L6.87742 10.4511L4.69513 9.6866Z" fill="white"/>
    <path d="M12.709 0.0765083C12.5856 -0.0131417 12.4236 -0.0252417 12.2894 0.0468083L0.216238 6.50931C0.0735057 6.58576 -0.0107384 6.74196 0.00106652 6.90641C0.013408 7.07141 0.120189 7.21221 0.272043 7.26556L3.62839 8.44146L10.7763 2.17696L5.24512 9.00741L10.8702 10.9781C10.912 10.9924 10.956 11.0001 11 11.0001C11.073 11.0001 11.1454 10.9797 11.2093 10.9401C11.3112 10.8763 11.3805 10.7691 11.3982 10.6486L12.8738 0.473608C12.8958 0.319608 12.8325 0.166708 12.709 0.0765083Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_2_149">
    <rect width="12.878" height="13.2" fill="white"/>
    </clipPath>
    </defs>
</svg>
)

type SendButtonProps = {
  onClick: () => void,
}

const SendButton: React.FC<SendButtonProps> = ({ onClick }) => {
  return (
    <button onClick={onClick} role="button" type="button" className="send__button">
      <SendIcon />
    </button>
  )
}

export default SendButton;