import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store';
import { selectSelectedMessageThread } from '../../../../store/Conversation/selector';
import { FetchProps, fetchSMSHistoryFromContactId } from '../../../../store/SMSHistory/actions';
import { selectSMSHistory } from '../../../../store/SMSHistory/selector';
import { SMSHistory } from '../../../types/SMSHistory';
import { MessageThread } from '../../Conversations/types/MessageThread';
import './index.scss';
import SMSHistoryCard from './SMSHistoryCard';

const SMSHistoryWidget = () => {
  const dispatch = useAppDispatch();
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread;
  const smsHistory = useSelector((state) => selectSMSHistory(state)) as SMSHistory
  useEffect(() => {
    if (selectedMessageThread) {
      dispatch(fetchSMSHistoryFromContactId({ id: selectedMessageThread.customers[0].id } as FetchProps))
    }
  }, [selectedMessageThread])
  return (
    <div className="sms_history__container">
      <div className="history__container">
        {smsHistory && smsHistory.results.length > 0 && smsHistory.results.map((result, index) => (
          <SMSHistoryCard key={`dealcard-${index}`} messageThread={result} />
        ))}
        {(!smsHistory || smsHistory.results.length === 0) && <p className="no_history">There is no SMS history.</p>}
      </div>
    </div>
  )
}

export default SMSHistoryWidget;
