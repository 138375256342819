import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AuthenticationLayout from '../components/AuthenticationLayout';
import ContactElementsContainer from '../components/Dashboard/Conversations/ContactElementsContainer';
import ContactNavBar from '../components/Dashboard/Conversations/ContactNavBar';
import ContactTabs from '../components/Dashboard/Conversations/ContactTabs';
import ConversationsContainer from '../components/Dashboard/Conversations/ConversationsContainer';
import ConversationsFilterBar from '../components/Dashboard/Conversations/ConversationsFilterBar';
import CustomerConversationList from '../components/Dashboard/Conversations/CustomerConversationsList';
import CustomerInfo from '../components/Dashboard/Conversations/CustomerInfo';
import MessagesView from '../components/Dashboard/Conversations/MessagesView';
import { MessageThread } from '../components/Dashboard/Conversations/types/MessageThread';
import { TabTypes } from '../components/Dashboard/Conversations/types/TabTypes';
import DashboardLayout from '../components/Dashboard/DashboardLayout';
import DashboardSidebarNav from '../components/SidebarNav';
import NavElement from '../components/Dashboard/NavElement';
import UpdateForm from '../components/Dashboard/UpdateForm/UpdateForm';
import CallWidget from '../components/Dashboard/Widgets/CallWidget';
import DealsWidget from '../components/Dashboard/Widgets/DealsWidget';
import SMSHistoryWidget from '../components/Dashboard/Widgets/SMSHistoryWidget';
import WidgetNavigation from '../components/Dashboard/Widgets/WidgetNavigation';
import WidgetsContainer from '../components/Dashboard/Widgets/WidgetsContainer';
import WidgetSelection from '../components/Dashboard/Widgets/WidgetSelection';
import ModalContainer from '../components/Modals/ModalContainer';
import { Widgets } from '../components/types/Widgets';
import { selectSelectedMessageThread, selectSelectedTab, selectSuccessfullyAssigned } from '../store/Conversation/selector';
import { selectOpenUpdateModal } from '../store/UpdateConnection/selectors';
import { selectCurrentWidget } from '../store/Widget/selector';
import { selectOpenContactModal } from '../store/Contacts/selector';
import AssignContactModal from '../components/Dashboard/AssignContactModal';
import { useAppDispatch } from '../store';
import { fetchConversations } from '../store/Conversation/actions';
import { resetSuccessfullyAssigned, setSelectedMessageThread } from '../store/Conversation/reducer';
import { selectOpenDealModal } from '../store/Deals/selector';
import DealModal from '../components/Dashboard/DealModal';
import { selectSelectedView } from '../store/Navigation/selector';
import { Settings } from './settings';
import { NavViewTypes } from '../components/SidebarNav/NavViewTypes';
import { ThemeProvider } from '../utils/ThemeContext';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread;
  const selectedView = useSelector((state) => selectSelectedView(state)) as NavViewTypes;
  const selectedTab = useSelector((state) => selectSelectedTab(state)) as TabTypes;
  const selectedWidget = useSelector((state) => selectCurrentWidget(state)) as Widgets | null
  const openUpdateModal = useSelector((state) => selectOpenUpdateModal(state));
  const openContactModal = useSelector((state) => selectOpenContactModal(state)) as boolean;
  const successfullyAssigned = useSelector((state) => selectSuccessfullyAssigned(state)) as boolean;
  const openDealModal = useSelector((state) => selectOpenDealModal(state)) as boolean;

  useEffect(() => {
    if (successfullyAssigned) {
      // Handle notification and maybe reset selected message thread
      dispatch(fetchConversations(null));
      dispatch(setSelectedMessageThread(null));
      dispatch(resetSuccessfullyAssigned());
    }
  }, [successfullyAssigned])

  const onBack = () => {
    // If Home page or something
  }

  return (
    <ThemeProvider>
      <AuthenticationLayout>
        <DashboardLayout>
          {/* Side bar nav */}
          <DashboardSidebarNav />
          {/* List of conversations and filters */}
          {selectedView === NavViewTypes.Conversation && (
            <>
              <ConversationsContainer>
                <NavElement onBack={onBack} title="Conversations" />
                <ConversationsFilterBar />
                <hr />
                <CustomerConversationList />
              </ConversationsContainer>
              {/* Contact Conversation elements */}
              <ContactElementsContainer>
                {selectedMessageThread && (
                  <div>
                    <ContactNavBar />
                    <ContactTabs />
                  </div>
                )}
                {selectedMessageThread && selectedTab === TabTypes.Chat && (
                  <MessagesView />
                )}
                {selectedMessageThread && selectedTab === TabTypes.Info && (
                  <CustomerInfo />
                )}
                {/* TODO add Customer Notes */}
                {selectedMessageThread && selectedTab === TabTypes.Notes && (
                  <div style={{ flex: '1 1 0%', justifyContent: 'center', display: 'flex' }}>
                    <p style={{ opacity: 0.4 }}>Notes not yet available.</p>
                  </div>
                )}
              </ContactElementsContainer>
              {/* Widgets */}
              {selectedMessageThread && (
                <WidgetsContainer>
                  {!selectedWidget && (<WidgetSelection />)}
                  {selectedWidget && (
                    <WidgetNavigation title={selectedWidget} />
                  )}
                  {selectedWidget === Widgets.Call_History && (
                    <CallWidget />
                  )}
                  {selectedWidget === Widgets.Deals && (
                    <DealsWidget />
                  )}
                  {selectedWidget === Widgets.SMS_History && (
                    <SMSHistoryWidget />
                  )}
                </WidgetsContainer>
              )}
              {/* Modals */}
              {openUpdateModal && (
                <ModalContainer>
                  <UpdateForm />
                </ModalContainer>
              )}
              {openContactModal && (
                <ModalContainer>
                  <AssignContactModal />
                </ModalContainer>
              )}
              {openDealModal && (
                <ModalContainer>
                  <DealModal />
                </ModalContainer>
              )}
            </>)}
          {selectedView === NavViewTypes.Settings && (
            <Settings />
          )}
        </DashboardLayout>
      </AuthenticationLayout>
    </ThemeProvider>
  )
}

export default Dashboard;
