import React, { useState, useRef } from 'react';
import cn from 'classnames';
import useOutsideAlerter from '../../Hooks/useOutsideAlerter';
import './OptionsButton.scss';

export type Option = {
  title: string,
  id: any,
}

type OptionsButtonProps = {
  onClick?: () => void,
  options?: Option[],
  optionClick?: (option: any) => void,
  topPosition?: boolean
}
const OptionsButton:React.FC<OptionsButtonProps> = ({ onClick, options, optionClick, topPosition = false }) => {
  const ref = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  useOutsideAlerter({ ref: ref, clickedOutside: () => {
    setShowOptions(false);
  }});

  const optionClickHandler = (option: Option) => {
    if (optionClick) {
      optionClick(option)
      setShowOptions(false);
    }
  }

  return (
    <div ref={ref} className="options__container">
      <button className="options__button" type="button" role="button" onClick={() => setShowOptions(!showOptions)}>
        <span />
        <span />
        <span />
      </button>
      {showOptions && options && optionClick && (
        <div className={cn("option_selection__container", {['placed_top']:  topPosition})}>
          {options.map((option, index) => (
            <div key={`${option.title}-${index}`} className="option__item" onClick={() => optionClickHandler(option)}>
              <p>{option.title}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default OptionsButton;
