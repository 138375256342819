import React from 'react';
import './index.scss';

const DashboardLayout: React.FC = ({ children }) => (
  <div className="dashboard__container">
    {children}
  </div>
);

export default DashboardLayout;
