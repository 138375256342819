import React, { useEffect } from "react"
import Select from "react-select"

const Dropdown = ({ fieldItem, onChange, value }: any) => {
  useEffect(() => {
    if (fieldItem.default) {
      onChange(fieldItem.default)
    }
  }, [])

  const options = fieldItem.options.map((opt: any) => {
    return { label: opt.label ? opt.label : opt, value: opt.value ? opt.value : opt }
  })
  let val = value.value ? value.value : value;
  const currentOption = options.filter((item: any) => item.value === val)
  let currentValue = null
  if (currentOption.length > 0) {
    currentValue = currentOption[0]
  }
  return (
    <div className="fieldContainer" key={fieldItem.keyName}>
      <label>
        <b>
          {fieldItem.label}
          {fieldItem.required && " *"}
        </b>
      </label>
      <Select
        className="defaultSelect"
        value={currentValue}
        options={options}
        onChange={(option: any) => onChange(option.value)}
      />
    </div>
  )
}

export default Dropdown
