import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useDefaultFormHook from './useDefaultFormHook';
import useFormBuilderHook from './useFormBuilderHook';
import { useSelector } from 'react-redux';
// import { selectDeal } from '../../../store/Deal/selector';
// import { selectUser } from '../../../store/User/selectors';
// import { selectAllContacts } from '../../../store/Communications/selectors';
import './UpdateForm.scss';
import { useAppDispatch } from '../../../store';
import { openUpdateModal, resetUpdateState } from '../../../store/UpdateConnection/reducer';
import { updateConnection } from '../../../store/UpdateConnection/actions';
import { selectUpdateConnectionErrorMessage, selectUpdateConnectionLoading, selectUpdateConnectionSuccessfullyUpdated } from '../../../store/UpdateConnection/selectors';
import Loading from '../../Loading/Loading';
import { DealResults } from '../../types/Deals';
import { Agent } from '../../types/Agent';
import { selectSelectedDeal } from '../../../store/Deals/selector';
import { selectSelectedMessageThread } from '../../../store/Conversation/selector';
import { MessageThread } from '../Conversations/types/MessageThread';
import DefaultButton from '../../Buttons/DefaultButton';

// ONLY DISPLAY THIS IF DEAL AND AGENT ARE PRESENT
// ENDPOINT: https://portal.listwithclever.com/deals/receive-status-update/formstack/
/*
  Logic Tree:
  NON UNIQUE FIELDS: (every stage has these fields)
    dealId (already have) - required
    agent vid (already have) - required
    Your Email (already have) - required (user email)
    Deal Type (already have) - required: options:  Sell, Buy
    Notes: string
    Internal Notes: string

  DIFFERENT UPDATE STAGES:
    keyName: Update Stage - required
    COMMUNICATING: value - 9
      Follow-up Date: string - format: Dec 31, 2018
    NOT CONNECTED: value - 5
      Follow-up Date: string - format: Dec 31, 2018
    MEETING SCHEDULED: value - 12
      Appointment Date: string - format: Dec 31, 2018
    MET / HOUSE HUNTED: value - 10
      Appointment Date: string - format: Dec 31, 2018
    AGREEMENT SIGNED: value - 13
      Follow-up Date: string - format: Dec 31, 2018
    ON MARKET / MAKING OFFERS: value - 14
    UNDER CONTRACT: value - 15
      Closing Date: string - format: Dec 31, 2018
    AWAITING PAYMENT: value - 16
    LOST: value - 1
      Release Reason: (dropdown) - required
        Customer chose a different agent
        Customer decided not to sell
        Customer decided to sell FSBO
        Customer went cold / became unreachable
        Customer had incorrect contact information
        I'm no longer interested in working with this customer
        Home Sold - Uncollectible/No Receipt of Payment
    CLOSED: value - 18
      Amount Paid: number - 0 decimal places
      Agent Reported Sale Price: number - 0 decimal places
      Reported Agent Commission Amount: number - 2 decimal places
      Reported Other Agent Commission Amount: number - 2 decimal places
      Expected Referral Fee: number - 0 decimal places
      Check Number: number - 0 decimal places
      Check Date: string - format: Dec 18, 2018
      Check Sent Date: string - format: Dec 18, 2018
      Commission Documents Sent Date: string - format: Dec 18, 2018
      Final Sale Price: number - 0 decimal places
      Is Commission Verified: radio buttons
        Verified, agent charged correct amount
        Verified, agent over-charged customer
        Unverified
      Commission Documents Sent: radio buttons
        Yes, in the envelope with the check
        Yes, by email
        No
      Payment Notes: string
    UNDERPAID: value - 17
      Amount Paid: number - 0 decimal places
      Agent Reported Sale Price: number - 0 decimal places
      Reported Agent Commission Amount: number - 2 decimal places
      Reported Other Agent Commission Amount: number - 2 decimal places
      Expected Referral Fee: number - 0 decimal places
      Check Number: number - 0 decimal places
      Check Date: string - format: Dec 18, 2018
      Check Sent Date: string - format: Dec 18, 2018
      Commission Documents Sent Date: string - format: Dec 18, 2018
      Final Sale Price: number - 0 decimal places
      Is Commission Verified: radio buttons
        Verified, agent charged correct amount
        Verified, agent over-charged customer
        Unverified
      Commission Documents Sent: radio buttons
        Yes, in the envelope with the check
        Yes, by email
        No
      Payment Notes: string
      Underpaid Differencce: number - 0 decimal places
*/

const UpdateForm = () => {
  const dispatch = useAppDispatch();
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread;
  const deal = useSelector(state => selectSelectedDeal(state)) as DealResults;
  // const contacts = useSelector(state => selectAllContacts(state)) as Contacts;
  // const user = useSelector(state => selectUser(state)) as User;
  const successfullyUpdated = useSelector(state => selectUpdateConnectionSuccessfullyUpdated(state));
  const error = useSelector(state => selectUpdateConnectionErrorMessage(state));
  const loading = useSelector(state => selectUpdateConnectionLoading(state));
  
  const [agentOptions, setAgentOptions] = useState<any[]>([]);
  const [selectedAgent, setSelectedAgent] = useState(null) as any;
  const [user, setUser] = useState<any>(null);
  // Hooks
  const [defaultFields, defaultData] = useDefaultFormHook();
  const [stageFields, otherData] = useFormBuilderHook({
    selectedUpdateStage: defaultData && defaultData['Update Stage']
    ? defaultData['Update Stage'] : null
  });

  useEffect(() => {
    if (selectedMessageThread) {
      setUser(selectedMessageThread.assigned_to)
    }
  }, [selectedMessageThread])

  useEffect(() => {
    if (error) {
      toast(error, { type: 'error' })
      dispatch(resetUpdateState());
    }
  }, [error])

  useEffect(() => {
    if (deal) {
      const options = [];
      if (deal.winning_agent) {
        options.push({
          label: `${deal.winning_agent.firstname} ${deal.winning_agent.lastname}`,
          value: deal.winning_agent.vid,
        })
      }
      if (deal.losing_agents && deal.losing_agents.length > 0) {
        deal.losing_agents.map((agent: Agent) => {
          return {
            label: `${agent.firstname} ${agent.lastname}`,
            value: agent.vid,
          }
        })
      }
      setAgentOptions(options);
    }
  }, [deal])

  const update = () => {
    if (selectedAgent && selectedAgent.value) {
      const baseProps = {
        'agent vid': selectedAgent.value,
        dealId: deal.hubspot_deal_id,
        'Your Email': user.email,
        'Deal Type': deal.transaction_type,
        ...defaultData,
        ...otherData,
      }

      dispatch(updateConnection(baseProps));
    }
  };

  const onClose = () => {
    dispatch(openUpdateModal(false));
    dispatch(resetUpdateState());
  }

  return (
    <>
      <div className="updateForm">
        <div className="templateRow">
          <h2>Update Connection</h2>
          <button className="close" onClick={onClose} />
        </div>
        {!successfullyUpdated && (
          <>
            {agentOptions.length > 0 && (
              <div className="fieldContainer">
                <label>Select Agent</label>
                <Select
                  className="defaultSelect"
                  value={selectedAgent}
                  options={agentOptions}
                  onChange={(val) => setSelectedAgent(val)}
                />
              </div>
            )}
            {defaultFields}
            {stageFields}
            <DefaultButton className="med_button" text="Update Connection" callback={update} />
          </>
        )}
        {successfullyUpdated && (
          <p className="success centered">Successfully Updated Connection! You may now close this modal.</p>
        )}
      </div>
      {loading && <Loading />}
      {/* <ToastContainer newestOnTop/> */}
    </>
  )
}

export default UpdateForm;
