import React from 'react';
import cn from 'classnames';
import './index.scss';
import { useSelector } from 'react-redux';
import { selectSelectedTab } from '../../../../store/Conversation/selector';
import { TabTypes } from '../types/TabTypes';
import { useAppDispatch } from '../../../../store';
import { setSelectedTab } from '../../../../store/Conversation/reducer';

const ContactTabs = () => {
  const dispatch = useAppDispatch();
  const selectedTab = useSelector((state) => selectSelectedTab(state)) as TabTypes;
  const onTabClick = (tab: string) => {
    dispatch(setSelectedTab(tab));
  }

  return (
    <div className="contact_tabs__container">
      <div className="contact_tabs__list">
        <div
          onClick={() => onTabClick(TabTypes.Chat)}
          className={cn(["contact_tabs__tab", {['selected__tab']: selectedTab === TabTypes.Chat }])}
        >
          Chat
        </div>
        <div
          onClick={() => onTabClick(TabTypes.Info)}
          className={cn(["contact_tabs__tab", {['selected__tab']: selectedTab === TabTypes.Info }])}
        >
          Info
        </div>
        <div
          onClick={() => onTabClick(TabTypes.Notes)}
          className={cn(["contact_tabs__tab", {['selected__tab']: selectedTab === TabTypes.Notes }])}
        >
          Notes
        </div>
      </div>
    </div>
  )
}

export default ContactTabs;
