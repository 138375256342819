import React, { useEffect, useState } from 'react';
import "../layout.scss"
import ReactGA from 'react-ga';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch } from '../../store';
// import { getTokensFromMagicLink } from '../../store/MagicLinks/actions';
import { navigate } from 'gatsby-link';
import { AuthCheck, AuthenticationTypes, isAuthenticated } from '../../utils/helpers';
import { useSelector } from 'react-redux';
// import { selectMagicLinksError, selectMagicLinksSuccess } from '../../store/MagicLinks/selectors';

const AuthenticationLayout = ({ children } : any) => {
  // const dispatch = useAppDispatch();
  // const magicLinkSuccess = useSelector((state) => selectMagicLinksSuccess(state));
  const [localLoading, setLocalLoading] = useState(true);
  // const magicLinkError = useSelector((state) => selectMagicLinksError(state));
  
  const addSmartLookScript = () => {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      // eslint-disable-next-line max-len
      
      script.innerHTML = `window.smartlook||(function(d) {
        var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
        var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
        c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);
        })(document);
        smartlook('init', '1fe1d63b34d9d4e920924467b9cebff1ca950e00');
        `;
      document.head.appendChild(script);
    }
  }

  useEffect(() => {
    setLocalLoading(true);
    addSmartLookScript();
    
    if (typeof window !== 'undefined') {
      ReactGA.initialize('UA-98380940-8');
      ReactGA.pageview(window.location.pathname + window.location.search);
      const urlParams = new URLSearchParams(window.location.search);
      const magicUuid = urlParams.get('magic_uuid');
      const authCheck = AuthCheck(magicUuid, isAuthenticated());
      switch(authCheck) {
        case AuthenticationTypes.NotAuthenticated:
          toast('No magic uuid available', { type: 'error' });
          navigate('/404/', {
            state: {
              error: 'No Magic Link Available'
            }
          })
          return;
        case AuthenticationTypes.FetchMagicLink:
          // dispatch(getTokensFromMagicLink(magicUuid!));
          setLocalLoading(false);
          return;
        default:
          setLocalLoading(false);
          return;
      }
    }
  }, []);

  // useEffect(() => {
  //   if (magicLinkSuccess) {
  //     setLocalLoading(false);
  //   }
  // }, [magicLinkSuccess])

  // useEffect(() => {
  //   if (magicLinkError) {
  //     // There was an error lets tell the user that there was an error with the magic link
  //     setLocalLoading(false);
  //     navigate('/');
  //   }
  // }, [magicLinkError])


  return (
    <>
       {!localLoading && (
        <div className="fullWidthContainer">
          {children}
        </div>
       )}
       <ToastContainer newestOnTop />
     </>
  )
}

export default AuthenticationLayout;
