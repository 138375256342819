import React, { useState } from 'react';
import { FieldTypes } from '../../../utils/fieldTypes';
import { Date, Dropdown, RadioNumber, RadioText, TextArea, TextField } from '../../FormBuilder/components';
import { UpdateStageOptions } from './Fields';

const DefaultFields = [
  {
    label: 'Update Stage',
    keyName: 'Update Stage',
    type: FieldTypes.dropdown,
    options: UpdateStageOptions,
  },
  {
    label: 'Notes',
    keyName: 'Notes',
    type: FieldTypes.textarea,
  },
  {
    label: 'Internal Notes',
    keyName: 'Internal Notes',
    type: FieldTypes.textarea,
  },
]

const useDefaultFormHook = (fields = DefaultFields) => {
  const [data, setData] = useState({}) as any;
  
  const handleOptionChange = (key: string, value: any) => {
    const updatedField = { ...data }
    updatedField[key] = value.value;
    setData(updatedField)
  }

  const updateFieldWithValue = (key: string, value: string | number) => {
    const updatedField = { ...data }
    updatedField[key] = value;
    setData(updatedField)
  }

  const getJSXFieldElement = (fieldItem: any) => {
    // Since all default fields are textareas we do not need the switch logic
    switch (fieldItem.type) {
      case FieldTypes.number:
      case FieldTypes.textfield:
        return (
          <TextField
            fieldItem={fieldItem}
            onChange={(value: string) =>
              setData((data: any) =>
                Object.assign({ ...data, [fieldItem.keyName]: value })
              )
            }
            value={data[fieldItem.keyName] || ""}
            key={fieldItem.keyName}
          />
        );
      case FieldTypes.textarea:
        return (
          <TextArea
            fieldItem={fieldItem}
            onChange={(value: string) =>
              setData((data: any) =>
                Object.assign({ ...data, [fieldItem.keyName]: value })
              )
            }
            value={data[fieldItem.keyName] || ""}
            key={fieldItem.keyName}
          />
        )
        case FieldTypes.radioNumber:
      return (
        <RadioNumber
          fieldItem={fieldItem}
          onChange={(value: string) =>
            setData((data: any) =>
              Object.assign({ ...data, [fieldItem.keyName]: value })
            )
          }
          value={data[fieldItem.keyName] || ""}
          key={fieldItem.keyName}
        />
      )
    case FieldTypes.radioText:
      return (
        <RadioText
          fieldItem={fieldItem}
          onChange={(value: string) =>
            setData((data: any) =>
              Object.assign({ ...data, [fieldItem.keyName]: value })
            )
          }
          value={data[fieldItem.keyName] || ""}
          key={fieldItem.keyName}
        />
      )
    case FieldTypes.date:
      return (
        <Date
          fieldItem={fieldItem}
          onChange={(value: string) =>
            setData((data: any) =>
              Object.assign({ ...data, [fieldItem.keyName]: value })
            )
          }
          value={data[fieldItem.keyName] || ""}
          key={fieldItem.keyName}
        />
      )
    case FieldTypes.header:
      return (
        <h3 key={fieldItem.keyName} className="fieldHeader">
          {fieldItem.label}
        </h3>
      )
      default:
        return (
          <Dropdown
            fieldItem={fieldItem}
            onChange={(value: string) =>
              setData((data: any) =>
                Object.assign({ ...data, [fieldItem.keyName]: value })
              )
            }
            value={data[fieldItem.keyName] || ""}
            key={fieldItem.keyName}
          />
        )
    }
  }

  const getAllFields = () => {
    const fieldsJSX = fields.map((field) => {
      return getJSXFieldElement(field);
    })
    return fieldsJSX;
  }


  return [getAllFields(), data]
}

export default useDefaultFormHook