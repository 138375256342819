import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import format from 'date-fns/format';
import './index.scss';
import { useSelector } from 'react-redux';
import { selectSelectedMessageThread } from '../../../../../store/Conversation/selector';
import { MessageThread } from '../../types/MessageThread';
import { Message as MessageObj } from '../../types/Message';
import useOutsideAlerter from '../../../../Hooks/useOutsideAlerter';
import { useAppDispatch } from '../../../../../store';
import { deleteMessage } from '../../../../../store/Message/actions';
import ClockIcon from '../../../../Icons/ClockIcon';

type MessageProps = {
  body: string,
  username: string,
  timestamp: string,
  message: MessageObj
}

const AlertIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M12,6 C12.5522847,6 13,6.44771525 13,7 L13,13 C13,13.5522847 12.5522847,14 12,14 C11.4477153,14 11,13.5522847 11,13 L11,7 C11,6.44771525 11.4477153,6 12,6 Z"/>
  </svg>
);

const Message: React.FC<MessageProps> = ({ body, username, timestamp, message }) => {
  const dispatch = useAppDispatch();
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread;
  const [showMessageOptions, setShowMessageOptions] = useState(false);
  const ref = useRef(null);
  useOutsideAlerter({ ref: ref, clickedOutside: () => {
    setShowMessageOptions(false);
  }})
  // Check to see if it is a delayed message
  const delayHasPassed = () => {
    if (message.send_at) {
      const currentDate = new Date();
      const delayedDate = new Date(message.send_at)
      if (delayedDate > currentDate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  useEffect(() => {
    // Check delay every 5 seconds
    let interval: any;

    if (!delayHasPassed()) {
      interval = setInterval(() => {
        setHasDelay(!delayHasPassed())
      }, 5000)
    }
    
    return () => {
      clearInterval(interval);
    }
  }, []);

  // Check if current user
  const isUser = () => {
    return selectedMessageThread.customers[0].phone === username;
  }
  // Delay Check
  const [hasDelay, setHasDelay] = useState(!delayHasPassed());

  const handleCancel = (e: any) => {
    // Check right click
    e.preventDefault();
    if (e.type === 'contextmenu') {
      if (!delayHasPassed()) {
        setShowMessageOptions(true);
      }
    }
  }

  const cancelMessage = () => {
    if (!delayHasPassed()) {
      // Cancel message and hide options
      const confirm = window.confirm('Are you sure you want to cancel this message?');
      if (confirm) {
        setShowMessageOptions(false);
        dispatch(deleteMessage(message.id))
      }
    }
  }


  return (
      <div className={cn(['message_bubble', { ['user_bubble']: !isUser(), ['highlighted']: hasDelay }])} onClick={handleCancel} onContextMenu={handleCancel} ref={ref}>
        <div className="name_section">
          <h5>{message.sent_by ? `${message.sent_by.first_name || ''} ${message.sent_by.last_name || ''}` : username}</h5>
          {!hasDelay && (<p>{format(new Date(message.delivered_at || message.sent_at), 'MM-dd-yyyy HH:mm aa')}</p>)}
          {message.send_at && hasDelay ? <div className="send_at__container">
            <p><ClockIcon /> {format(new Date(message.send_at!), 'MM-dd-yyyy HH:mm aa')}</p>
          </div> : ''}
        </div>
        <p className="text_bubble">{body}</p>
        {message.errored_at && (
          <div className="alert_icon">
            <AlertIcon />
            <p>Error sending message</p>
          </div>
        )}
        {showMessageOptions && (
          <div className="message_options__container">
            <div className="message_option" onClick={() => cancelMessage()}>
              <p>Cancel Pending Message</p>
            </div>
          </div>
        )}
      </div>
      
  )
}

export default Message;
