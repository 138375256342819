import React from 'react';
import { SVGProps } from './types';

const ArchiveBox: React.FC<SVGProps> = ({ onClick }) => (
  <svg onClick={onClick} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.09 15.4297V6.16199C17.0234 6.16741 16.9568 6.17102 16.8902 6.17102H1.10967C1.04308 6.17102 0.974647 6.16741 0.909912 6.16199V15.4297C0.909912 16.0275 1.40745 16.5134 2.01965 16.5134H15.9802C16.5924 16.5134 17.09 16.0293 17.09 15.4297ZM12.7065 9.73288C12.7065 10.0327 12.4586 10.2747 12.1516 10.2747H5.84826C5.54124 10.2747 5.29339 10.0327 5.29339 9.73288V8.29514C5.29339 7.99529 5.54124 7.75327 5.84826 7.75327H12.1498C12.4568 7.75327 12.7046 7.99529 12.7046 8.29514V9.73288H12.7065Z" fill="#8F9091"/>
    <path d="M18 4.00356V3.06975C18 2.47189 17.5025 1.98602 16.8903 1.98602H1.10974C0.497534 1.98602 0 2.47189 0 3.06975V4.00356C0 4.53458 0.392109 4.9771 0.909989 5.06922C0.974724 5.08005 1.04131 5.08728 1.10974 5.08728H16.8884C16.9569 5.08728 17.0235 5.08005 17.0882 5.06922C17.6079 4.9771 18 4.53458 18 4.00356Z" fill="#8F9091"/>
  </svg>

)

export default ArchiveBox;
