import React from 'react';
import './index.scss';
import Logo from '../Icons/Logo';
import ConversationsIcon from '../Icons/ConversationsIcon';
import SettingsIcon from '../Icons/SettingsIcon';
import  { NavViewTypes }  from './NavViewTypes';
import { useAppDispatch } from '../../store';
import { selectSelectedView } from '../../store/Navigation/selector';
import { useSelector } from 'react-redux';
import { setSelectedView } from '../../store/Navigation/reducer';
import cn from 'classnames';

const DashboardSidebarNav = () => {
  const dispatch = useAppDispatch();
  const selectedView = useSelector((state) => selectSelectedView(state)) as NavViewTypes;

  function selectDashboardView(view: NavViewTypes) {
    dispatch(setSelectedView(view));
  }

  return (
    <div className="sidebar">
      <Logo />
      <div className="sidebar_nav">
        
        <div className={cn(['sidebar_nav__item', { ['selected']: selectedView === NavViewTypes.Conversation }])}>
          <ConversationsIcon onClick={() => selectDashboardView(NavViewTypes.Conversation)} />
        </div>
        <div className={cn(['sidebar_nav__item settings', { ['selected']: selectedView === NavViewTypes.Settings }])}>
          <SettingsIcon onClick={() => selectDashboardView(NavViewTypes.Settings)} />
        </div>
      </div>
    </div>
  )
}

export default DashboardSidebarNav;
