import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store';
import { fetchTeams, fetchUsers } from '../../../store/Contacts/actions';
import { closeContactModal } from '../../../store/Contacts/reducer';
import { selectAllTeams, selectAllUsers, selectOpenModalContactType } from '../../../store/Contacts/selector';
import { assignConversationToTeam, assignConversationToUser } from '../../../store/Conversation/actions';
import { selectSelectedMessageThread } from '../../../store/Conversation/selector';
import DefaultButton from '../../Buttons/DefaultButton';
import DefaultCard from '../../Cards/DefaultCard';
import { GroupResults, UserResults } from '../../types/Contacts';
import { ContactOptionTypes } from '../Conversations/ContactNavBar';
import { MessageThread } from '../Conversations/types/MessageThread';
import './index.scss';

const AssignContactModal = () => {
  const dispatch = useAppDispatch();
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread;
  const contactType = useSelector(state => selectOpenModalContactType(state)) as ContactOptionTypes;
  const teams = useSelector(state => selectAllTeams(state)) as GroupResults;
  const users = useSelector(state => selectAllUsers(state)) as UserResults;

  useEffect(() => {
    if (contactType === ContactOptionTypes.Team) {
      dispatch(fetchTeams());
    } else {
      dispatch(fetchUsers());
    }
  }, [contactType]);

  const onClose = () => {
    dispatch(closeContactModal());
  }

  const handleTeamClick = (teamId: number) => {
    dispatch(assignConversationToTeam({
      id: selectedMessageThread.id,
      teamId: teamId,
    }))
    dispatch(closeContactModal());
    
  }

  const handleUserClick = (userId: number) => {
    dispatch(assignConversationToUser({
      id: selectedMessageThread.id,
      userId: userId,
    }))
    dispatch(closeContactModal());
  }

  return (
    <div className="form__container">
      <div className="form_header__container">
        <h2>Assign To {contactType === ContactOptionTypes.Team ? 'Team' : 'User'}</h2>
        <button className="close" onClick={onClose} />
      </div>
      {contactType === ContactOptionTypes.Team && teams && teams.results && (
        <div className="teams_list__container">
          {teams.results.map((team, key) => (
            <DefaultCard key={`team-name__${key}`} selected={true} highlighted={false} onClick={() => {}}>
              <div className="team__item">
                <p>ID: {team.id}</p>
                <p>{team.name}</p>
                <DefaultButton text="Assign Conversation" className="small__button no__padding" callback={() => handleTeamClick(team.id)} />
              </div>
            </DefaultCard>
          ))}
        </div>
      )}
      {contactType === ContactOptionTypes.User && users && users.results && (
        <div className="teams_list__container">
          {users.results.map((user, key) => (
            <DefaultCard key={`user-name__${key}`} selected={true} highlighted={false} onClick={() => {}}>
              <div className="team__item">
                <p>ID: {user.id}</p>
                <p>{user.first_name} {user.last_name}</p>
                <DefaultButton text="Assign Conversation" className="small__button no__padding" callback={() => handleUserClick(user.id)} />
              </div>
            </DefaultCard>
          ))}
        </div>
      )}
    </div>
  )
}

export default AssignContactModal;
