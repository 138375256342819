import React from 'react';
import { useAppDispatch } from '../../../../store';
import { selectWidget } from '../../../../store/Widget/reducer';
import { Widgets } from '../../../types/Widgets';
import './index.scss';

const SMSIcon = () => (
  <svg width="44" height="44" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M57.8208 50.9057C61.7057 45.6088 64 39.0723 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64C38.5118 64 44.5692 62.055 49.6232 58.7142L61.7161 60.3033L57.8208 50.9057Z" fill="#229FE6"/>
    <circle cx="15" cy="32" r="5" fill="white"/>
    <circle cx="32" cy="32" r="5" fill="white"/>
    <circle cx="49" cy="32" r="5" fill="white"/>
  </svg>
)

const CallIcon = () => (
  <svg width="44" height="44" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.3683 19C15.3683 16 18.5896 16.8657 19.8683 13.5C20.6281 11.5 19.0641 9.01095 16.8683 6.49999C14.8822 4.22892 12.8683 4.41289e-05 11.3683 0C8.3264 -8.94895e-05 2.14772 3.99997 0.368281 8.5C-1.01572 12 1.76179 18.3179 4.36828 24C7.05614 29.8595 9.24773 33.0045 13.8683 37.5C22.3235 45.7264 37.3683 52.1827 41.3683 50C46.2405 47.3414 51.3683 41.5 50.3683 39C49.3683 36.5 46.5647 35.7442 44.3683 34L44.2644 33.9175C41.7748 31.9401 39.3409 30.0068 37.3683 30.5C34.1535 31.3037 34.3683 34.5 31.3683 36C29.8683 36.75 23.8728 32.6939 20.3683 29C17.2338 25.6961 13.8683 20.5 14.3683 19Z" fill="#229FE6"/>
  </svg>
)

const DealsIcon = () => (
  <svg width="44" height="44" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42.7817 10.5409C42.9703 10.398 43.1353 10.2309 43.2737 10.0457H51.8123C51.852 10.0457 51.8913 10.0447 51.931 10.0429C52.4973 10.016 53.0637 10.0161 53.628 10.0429C53.6673 10.0447 53.707 10.0457 53.7463 10.0457H53.874C55.7907 10.0457 57.6023 10.4659 59.228 11.2168C59.271 11.2367 59.3147 11.2553 59.3587 11.2728C62.4173 12.4797 65.1476 14.536 67.158 17.3476C70.1883 21.5853 71.1333 26.8983 69.8406 31.8414L58.902 21.0494C58.4343 20.5878 57.8037 20.3291 57.1463 20.3291H41.3303C41.2963 20.3291 41.2623 20.3298 41.2283 20.3311C40.7193 20.3519 40.228 20.5278 39.8207 20.8364L33.5387 25.5957C33.3617 25.7299 33.175 25.843 32.9819 25.9354C31.6297 26.5826 29.958 26.2179 29.0127 24.9831C28.8784 24.8077 28.7651 24.623 28.6725 24.432C28.0237 23.0948 28.3849 21.4484 29.6176 20.5145L42.7817 10.5409Z" fill="#229FE6"/>
    <path d="M26.3283 47.4523L26.2841 47.4956L23.0372 50.722L22.9946 50.7646C21.8178 51.8876 19.9475 51.8733 18.7885 50.7216C17.6148 49.5556 17.6148 47.665 18.7885 46.4986L22.0354 43.2726C23.2091 42.1063 25.1119 42.1063 26.2856 43.2726C27.4449 44.4243 27.4591 46.2833 26.3283 47.4523Z" fill="#229FE6"/>
    <path d="M25.3591 53.1147C24.2283 54.284 24.2425 56.1427 25.4018 57.2943C26.5754 58.4607 28.4783 58.4607 29.652 57.2943L32.8989 54.0683C34.0727 52.902 34.0727 51.0113 32.8989 49.8453C32.754 49.7013 32.598 49.575 32.4337 49.4667C31.2831 48.7077 29.7224 48.8197 28.6928 49.8023L28.6502 49.845L25.4032 53.0713L25.3591 53.1147Z" fill="#229FE6"/>
    <path d="M18.3353 38.0264C19.5089 39.1927 19.5089 41.0834 18.3353 42.2494L15.0883 45.4757C13.9147 46.6417 12.0118 46.6417 10.8381 45.4757C9.66447 44.3094 9.66447 42.4187 10.8381 41.2527L14.0851 38.0264C15.2587 36.8604 17.1616 36.8604 18.3353 38.0264Z" fill="#229FE6"/>
    <path d="M39.514 56.4177C40.6877 57.584 40.6877 59.4746 39.514 60.6406L36.267 63.867C36.026 64.106 35.7547 64.2963 35.465 64.4373C34.3433 64.984 32.9496 64.7937 32.0167 63.867C31.0838 62.94 30.8925 61.5553 31.4426 60.4407L31.4468 60.432C31.5883 60.1477 31.7783 59.8806 32.0167 59.644L35.2637 56.4177C36.4373 55.2517 38.3403 55.2517 39.514 56.4177Z" fill="#229FE6"/>
    <path d="M17.813 16.1036C22.4651 11.4812 28.7927 9.53817 34.8533 10.2746L26.5981 16.5292C22.9805 19.27 22.2836 24.4186 25.0425 28.0225C27.7954 31.6186 32.9483 32.3161 36.5583 29.5811L42.1703 25.3291H56.1206L67.8086 36.8603C67.846 36.9063 67.886 36.951 67.9286 36.9937L71.7616 40.827C73.6343 42.6997 73.6343 45.7357 71.7616 47.6083C69.9956 49.3743 67.195 49.475 65.3113 47.9103L64.9903 47.5893C64.927 47.526 64.86 47.469 64.79 47.418L61.147 43.7753C60.496 43.1243 59.441 43.1243 58.79 43.7753C58.139 44.426 58.139 45.4813 58.79 46.1323L62.6233 49.9653C62.762 50.104 62.9043 50.2367 63.05 50.3633L63.1963 50.5093C64.5226 51.836 64.5226 53.9867 63.1963 55.3133C61.8696 56.64 59.7186 56.64 58.3923 55.3133L57.8293 54.7503C57.1783 54.0993 56.123 54.0993 55.4723 54.7503C55.1383 55.084 54.976 55.524 54.9843 55.9613C54.9746 56.4003 55.137 56.8423 55.472 57.1773L56.2153 57.9207C57.4423 59.1477 57.4423 61.137 56.2153 62.364C54.9883 63.591 52.999 63.591 51.772 62.364L51.7683 62.3603L51.7293 62.3207L51.029 61.6207C50.7876 61.379 50.4903 61.227 50.1786 61.165C49.6503 61.0593 49.081 61.211 48.6716 61.6207C48.021 62.2713 48.021 63.3267 48.6716 63.9777L49.3996 64.7057C50.623 65.9587 50.614 67.966 49.3723 69.2077C48.1436 70.436 46.16 70.4613 44.9006 69.2643L40.1286 64.729L41.8783 62.9903C44.358 60.5263 44.358 56.532 41.8783 54.0683C40.5696 52.7677 38.8316 52.1537 37.1173 52.2263C37.1903 50.523 36.5723 48.7963 35.2633 47.4957C33.955 46.1957 32.2177 45.5817 30.5041 45.6537C30.5769 43.9507 29.9589 42.2237 28.6501 40.9233C26.9848 39.2683 24.6244 38.725 22.5026 39.293C22.3238 37.9707 21.7228 36.6933 20.6998 35.677C18.4117 33.4033 14.8117 33.2279 12.321 35.1503C10.7818 28.5184 12.6124 21.2708 17.813 16.1036Z" fill="#229FE6"/>
  </svg>
)

const WidgetSelection = () => {
  const dispatch = useAppDispatch();

  const onSelect = (widgetType: Widgets) => {
    dispatch(selectWidget(widgetType))
  }

  return (
    <div className="widget_selection__container">
      <h4>Widgets</h4>
      <div className="widgets">
        {/* Add all widgets here */}
        <div className="widget" onClick={() => onSelect(Widgets.Call_History)}>
          {/* Icon */}
          <CallIcon />
          <p>Call History</p>
        </div>
        <div className="widget" onClick={() => onSelect(Widgets.Deals)}>
          {/* Icon */}
          <DealsIcon />
          <p>Deals</p>
        </div>
        <div className="widget" onClick={() => onSelect(Widgets.SMS_History)}>
          {/* Icon */}
          <SMSIcon />
          <p>SMS</p>
        </div>
      </div>
    </div>
  )
}

export default WidgetSelection;