import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store';
import { selectSelectedMessageThread } from '../../../../store/Conversation/selector';
import { fetchMessagesFromConversationId, MessagesProps } from '../../../../store/Message/actions';
import { selectMessages, selectSuccessfullyDeleted } from '../../../../store/Message/selector';
import DefaultButton from '../../../Buttons/DefaultButton';
import useConversationHook from '../../../Hooks/ConversationHook';
import { Messages } from '../types/Message';
import { MessageThread } from '../types/MessageThread';
import Message from './Message/Message';
import MessageBar from './MessageBar';
import MessageViewContainer from './MessageViewContainer';
import './index.scss';
import { resetDeleteState } from '../../../../store/Message/reducer';
import { sendToast } from '../../../../utils/helpers';

const MessagesView = () => {
  const dispatch = useAppDispatch();
  const bottomRef = useRef(null);
  const [page, setPage] = useState(1);
  const selectedMessageThread = useSelector((state) => selectSelectedMessageThread(state)) as MessageThread;
  const _ = useConversationHook({ channelId: selectedMessageThread ? selectedMessageThread.id : '' })
  const messages = useSelector((state) => selectMessages(state)) as Messages
  const deletedMessage = useSelector((state) => selectSuccessfullyDeleted(state));

  useEffect(() => {
    if (selectedMessageThread) {
      const props = {
        page: page,
        id: selectedMessageThread.id,
      } as MessagesProps
      
      dispatch(fetchMessagesFromConversationId(props));
    }
  }, [selectedMessageThread])

  useEffect(() => {
    if (bottomRef && bottomRef.current) {
      (bottomRef.current as any).scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  useEffect(() => {
    if (deletedMessage) {
      const props = {
        page: page,
        id: selectedMessageThread.id,
      } as MessagesProps
      dispatch(fetchMessagesFromConversationId(props))
      dispatch(resetDeleteState())
      sendToast("Successfully cancelled message!", { type: 'success' })
    }
  }, [deletedMessage]);

  const fetchNextMessagess = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    const props = {
      page: nextPage,
      id: selectedMessageThread.id,
    } as MessagesProps

    dispatch(fetchMessagesFromConversationId(props));
  }

  const allMessages = messages && messages.results && messages.results.length > 0 ? [...messages.results].reverse() : [];
  return (
    <MessageViewContainer>
      {messages && messages.next !== null && (<DefaultButton className="show_more__button" text="Show More" callback={fetchNextMessagess} />)}
      <div className="messages_block">
        {messages && messages.results && allMessages.map((message, index) => (
          <Message
            key={`${message.id}-${index}`}
            message={message}
            username={message.sender}
            body={message.body}
            timestamp={message.sent_at}
          />
        ))}
        <div ref={bottomRef} />
      </div>
      <MessageBar />
    </MessageViewContainer>
  )
}

export default MessagesView;